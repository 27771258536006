
import { defineComponent, ref, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import { Actions } from "@/store/enums/StoreEnums"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import $ from 'jquery'
import WelcomeItem from './WelcomeItem.vue'
import DocumentationIcon from './icons/IconDocumentation.vue'
import ToolingIcon from './icons/IconTooling.vue'
import EcosystemIcon from './icons/IconEcosystem.vue'
import CommunityIcon from './icons/IconCommunity.vue'
import SupportIcon from './icons/IconSupport.vue'

export default defineComponent({
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const current_tab = ref('' as any)
    current_tab.value = 'h5'

    const change_tab = (tab) => {
      current_tab.value = tab
    }
      
    const goToHome = () => {
      router.push({ name: "home" })
    }
      
    const goToHtml = (id) => {
      router.push({ name: "html", params: {eppcm_id: route.params.id, id: id} })
    }

    const goToHtmlLink = () => {
      router.push({ name: "html_link" })
    }

    const goToExportGroup = () => {
      router.push({ name: "export_group", params: {id: route.params.id} })
    }

    const onChange = (e) => {
      console.log('onChange')

      store
        .dispatch(Actions.UPLOAD_HTML, {
          file: e.target.files[0],
          eppcm_id: route.params.id
        })
        .then(() => {
          Swal.fire({
            text: "上传成功",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "确认",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          })

          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const onUpload = () => {
      console.log('onUpload')
      $("#btn_upload").click()
    }

    const query = () => {
      store
        .dispatch(Actions.GET_HTMLS, {eppcm_id: route.params.id})
        .then(() => {
          htmls.value = store.getters.currentHtmls
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    query()

    const htmls = ref([] as any)

    const eppcm = ref({} as any)

    store
      .dispatch(Actions.GET_EPPCM, route.params.id)
      .then(() => {
        eppcm.value = store.getters.currentEppcm
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const onDelete = (id) => {
      Swal.fire({
        title: '确认删除？',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: '确认',
        // denyButtonText: '取消',
        cancelButtonText: '取消'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          store
            .dispatch(Actions.DELETE_HTML, id)
            .then(() => {
              query()
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
          // Swal.fire('Saved!', '', 'success')
        // } else if (result.isDenied) {
          // Swal.fire('Changes are not saved', '', 'info')
        }
      })
    }

    return {
      current_tab,
      change_tab,
      goToHome,
      goToHtml,
      goToHtmlLink,
      onChange,
      onUpload,
      htmls,
      eppcm,
      onDelete,
      goToExportGroup
    }
  }
})
